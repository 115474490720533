/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-07-05 11:39:03
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-07-11 08:39:40
 * @FilePath: \platform-pc-front\src\components\CenterMap\class\map.js
 * @Description: 地图类
 */

import axios from 'axios';
import { filterAliMapData } from '../mapUtils/index';
import china from '../mapJson/chinaMap.json';
import * as echarts from 'echarts'
import { getLatLon } from "@/api/getData";
import 'echarts-gl';
export default class Map {
    constructor(el, initOption, backLoading) {
      // echarts.registerMap('china', china)
        //echarts要挂载的节点
        this.el = el;
        //点击地图的路由栈
        this.mapStack = [];
        this.cityAdcode = '100000';
        this.cityName = '';
        this.provinceName = ''
        //地图数据对象
        this.mapObject = china;
        //保存当前option
        this.supOption = initOption;
        //刚创建自动初始化一次,同时返回保存echarts实例
        this.myChart = this.mapInit(initOption);
        this.backLoading = backLoading; 
    }

    //根据adcode获取地图geod数据
    /**
     * @description: 
     * @param {*} adcode 阿里地图的编号
     * @return {*} [{}] 处理获取回来的数据
     */
    async downloadedMaps(adcode = '100000', name) {
        let mapUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}_full.json`;
        let resultData = await getLatLon({url: mapUrl});
        if (!resultData) {
          this.backLoading(false)
          return false;
        }
        if (name.indexOf('省') > -1) {
          this.provinceName = name
        }
        //抽取数据
        let dataList = this.data = await filterAliMapData(resultData, (name && name.indexOf('省') === -1 ? this.provinceName +','+ name : name));
        this.mapObject = resultData.obj;
        return dataList;

    }

    //初始化图形
    mapInit(option, name = 'china', mapObject = this.mapObject) {
        if (!(this.el && option)) {
            console.error('地图初始化失败！');
            return;
        }
        //注册中国/下级地图(ps:'_'，代表已经注册过，用于返回函数)
        if (mapObject !== '_') {
          echarts.registerMap(name, mapObject)
        }
        if (this.myChart) {
            this.myChart.clear();
            this.myChart.setOption(option);
            this.backLoading()
            return this.myChart;
        }
        //销毁之前的实例
        //this.myChart = null;
        console.log(option.series[1].data);
        let mapChart = echarts.init(this.el);
        mapChart.setOption(option);
        return mapChart;
    }

    async mapChange() {
      const dataList = await this.downloadedMaps(this.cityAdcode,this.cityName)
      let scatterData=dataList.map((item)=>{
          if (item.local && item.local.length) {
            item.local.push(1)
          }
          return{
            name:item.value,
            value:item.local
          }
        })
        this.subOption.series[1].data = scatterData.filter(val=> val.name && val.name > 0);
        this.subOption.series[0].data = dataList;
      this.myChart.clear();
      this.myChart.setOption(this.subOption);
    }

    //返回上一级
    goBack(stackEmptyCallBack) {
        console.log("执行返回函数");
        if (!this.mapStack.length) {
            if (stackEmptyCallBack && (typeof (stackEmptyCallBack) === 'function')) {
                stackEmptyCallBack();
            }
            return;
        }
        //将栈顶的数据出栈返回给echarts重新渲染
        let mapData = this.mapStack.pop();
        console.log(mapData);
        this.mapInit(mapData, mapData.geo3D.map, '_')
        return mapData;
    }

    //下钻下一级行政区
    async goToNextLevel(adcode, name, subOption, supOption) {
        this.cityAdcode = adcode;
        this.cityName = name;
        //获取地图数据并返回处理后数据
        let dataList = await this.downloadedMaps(adcode, name);
        //将本级option保存用于返回时重新渲染
        this.mapStack.push(supOption);
        let scatterData=dataList.map((item)=>{
            if (item.local && item.local.length) {
              item.local.push(1)
            }
            return{
              name:item.value,
              value:item.local
            }
          })
        subOption.series[1].data = scatterData.filter(val=> val.name && val.name > 0);
        subOption.series[0].data = dataList;
        this.supOption = subOption
        // subOption.series[1].data = dataList;
        //渲染子图
        this.mapInit(subOption, name, this.mapObject)
    }

    //点击事件
    OnClickEvent(callback) {
        if (callback && typeof (callback) === 'function') {
            this.myChart.on('click', 'series.map3D', callback)
            return;
        }
    }

}