/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-07-05 09:01:35
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-07-09 11:04:55
 * @FilePath: \platform-pc-front\src\components\Chart\mapUtils\index.js
 * @Description: 地图相关方法
 */

import { getMapCarNumberInfo } from '@/api/Map/mapRequst.js';
import { getAreaVehicleCount } from "@/api/BigScreenDisplay";


//抽取阿里地图数据合并后台数据
export async function filterAliMapData(rawData, name, data, requestName='getMapCarNumberInfo') {
   if (!rawData) return [];
   //处理返回的数据
   let dataFeatures = rawData;
   if (rawData.obj) {
      dataFeatures = rawData.obj;
   }
   let mapResult = [{}];
   if (data) {
      mapResult = data.mapResult;
   } else {
      let carInfo = {}
      //获取后端数据
      if (requestName == 'getMapCarNumberInfo') {
         carInfo = await getMapCarNumberInfo(name ? { cityCountyName: name } : {});
      }else if (requestName == 'getAreaVehicleCount') {
         carInfo = await getAreaVehicleCount(name ? { area: name } : {});
      }
      if (requestName == 'getMapCarNumberInfo' && carInfo?.obj?.mapResult.length) {
         mapResult = carInfo.obj.mapResult;
      }else if (carInfo?.obj?.length && requestName == 'getAreaVehicleCount') {
         mapResult = carInfo.obj;
      }
   }
   //TODO 数据合并
   let dataList = [];
   dataFeatures.features ? dataList = dataFeatures.features.map((item) => {
      return {
         adcode: item.properties.adcode,
         name: item.properties.name,
         level: item.properties.level,
         local: item.properties.center
      };
   }) : dataList = dataFeatures;

   let resultDataList = dataList ? dataList.map((item) => {
      for (let i = 0; i < mapResult.length; i++) {
         const mi = mapResult[i];
         const itemStyle = { borderColor: '#FFBB33', opacity: 0 };
         if (item.adcode == mi.provinceCode) {
            //mapResult.remove(mi);
            return {
               ...item,
               value: mi.count,
               itemStyle: itemStyle
            };
         }

      }
      //没有匹配的证明没有数据
      return { ...item, value: 0 };
   }) : [];



   return resultDataList;
}
export async function filterMapData(rawData, name) {
   if (!rawData) return [];
   //处理返回的数据
   let dataFeatures = rawData;
   if (rawData.obj) {
      dataFeatures = rawData.obj;
   }
   let dataList = [];
   dataFeatures.features ? dataList = dataFeatures.features.map((item) => {
      return {
         adcode: item.properties.adcode,
         name: item.properties.name,
         level: item.properties.level,
         local: item.properties.center
      };
   }) : dataList = dataFeatures;
   return dataList;
}

