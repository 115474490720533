/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-07-06 15:48:38
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-07-11 11:01:47
 * @FilePath: \platform-pc-front\src\api\Map\mapRequst.js
 * @Description: 关于地图数据的请求
 */


import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);
import { getWeek, getTimes } from "@/utils/getDate";

/**
 * @description: 根据名字获取子级地区，车辆数量
 * @param {*} areasName 地区名字
 * @return {*} {...,obj.mapResult[{count,provinceCode,provinceName}]}
 */
export const getMapCarNumberInfo = (params) => axios2.get('dataCenter/getProductByProvinceCity.do', {
    params
}, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})


export async function getMapCarWorkTime(intervalMark) {
    let nowDay=dayjs(`${new Date()}`).format('YYYY-MM-DD');
    let startTime;
    let endTime;
    //intervalMark区间标记 0=天/1=周/2=月/3=年
    if(intervalMark===1){
        endTime=getWeek()[2];
        startTime=getWeek()[1];
    }else if(intervalMark===2){
        startTime=`${dayjs(nowDay).startOf('month').format('YYYY-MM-DD')} 00:00:00`;
        endTime=`${dayjs(nowDay).endOf('month').format('YYYY-MM-DD')} 23:59:59`;
    }else if(intervalMark===3){
        startTime=`${dayjs(nowDay).startOf('year').format('YYYY-MM-DD')} 00:00:00`;
        endTime=`${dayjs(nowDay).endOf('year').format('YYYY-MM-DD')} 23:59:59`;
    }




    let res = await axios.post('accReport/statisticWorkingTime', 
        { 
            pageNumber: 1, 
            pageSize: 10, 
            intervalMark,
            startTime,
            endTime,
            groupIds: [], 
            vehicleIds: [] 
        
        }
    , {
        headers: {
            'Content-Type': 'application/json',
        }
    });


    //抽取x轴日期标点
    let dayDury = [];

    
    
    let carData = res.obj.map((item) => {
        // if (item.vehicleStatisticalList.length==0) return { name: item.productLineType, timeData: [] };
        if (item.vehicleStatisticalList.length==0) return {};
        return {
            name: item.productLineType,
            timeData: item.vehicleStatisticalList.map((car) => {
                if(dayDury.length<item.vehicleStatisticalList.length){
                    dayDury.push(car.dateDay)
                }
                return (car.idleSpeed+car.walkingTime+car.workTime+car.shutdownTime+car.electricityTime) / 3600;//TODO 还有其他数据加起来

            })
        }
    });



    let seriesArr = carData?.map((item) => {
        let c1 = `rgb(${parseInt(Math.random() * 255)},${parseInt(Math.random() * 255)},${parseInt(Math.random() * 255)})`;
        let c2 = `rgb(${parseInt(Math.random() * 255)},${parseInt(Math.random() * 255)},${parseInt(Math.random() * 255)},1)`;
        return {
            name: item.name,
            data: item.timeData,
            type: 'line',
            smooth: 0.6,
            itemStyle: {
                normal: {
                    color: c1, //折点颜色
                    lineStyle: {
                        color: c1 //折线颜色
                    }
                }
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: c2
                }, {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0.3)'
                }])
            },
        }
    });
   
    return  {seriesArr,dayDury};

}