<!--
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-07-06 21:59:44
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-07-07 09:07:11
 * @FilePath: \platform-pc-front\src\views\BigScreenDisplay\PiChart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div ref="elpi"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'PiChart',
    mounted() {
        let mychart = echarts.init(this.$refs.elpi);
        mychart.setOption(this.piOption);
    },
    data() {
        return {
            piOption: {
                series: [
                    {
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 18
                        },
                        axisLine: {
                            lineStyle: {
                                width: 18
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                            length: 15,
                            lineStyle: {
                                width: 2,
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            show: false,
                            distance: 25,
                            color: '#999',
                            fontSize: 20
                        },
                        anchor: {
                            show: false,
                            showAbove: true,
                            size: 25,
                            itemStyle: {
                                borderWidth: 10
                            }
                        },
                        pointer: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 80,
                            offsetCenter: [0, '0%']
                        },
                        data: [
                            {
                                value: 70
                            }
                        ]
                    }
                ]
            },
        }
    }


}
</script>

<style>
.pi {
    z-index: 99999999;
}
</style>