<template>
  <div class="box-bg" :style="{ width: width }">
    <img src="../../../assets/screen/horn_top_left.png" class="horn_top_left" alt="">
    <img src="../../../assets/screen/horn_top_right.png" class="horn_top_right" alt="">
    <img src="../../../assets/images/bigScreen/xian.png" class="horn_top_top" alt="">
    <img src="../../../assets/images/bigScreen/xian.png" class="horn_top_bottom" alt="">
    <!-- <div class="title">
      <img src="../../../assets/screen/arrow_left.png" alt="">
      <span>{{ title }}</span>
      <img src="../../../assets/screen/arrow_right.png" alt="">
    </div> -->
    <div class="box-content">
      <div>
        <slot />
      </div>
    </div>
    <img src="../../../assets/screen/horn_bottom_left.png" class="horn_bottom_left" alt="">
    <img src="../../../assets/screen/horn_bottom_right.png" class="horn_bottom_right" alt="">
  </div>
</template>

<script>
import { stringify } from 'qs'
import { number } from 'echarts'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .box-bg {
    // background-position: 0;
    position: relative;
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    // background-image: url('../../../assets/screen/decorate2.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // padding-bottom: 20px;
    // padding-top: 10px;
    padding: .7% 0;
    padding-bottom: .8%;
    box-shadow: 0 0 28px rgba(0, 138, 255, 0.2) inset;
    > .title {
      display: flex;
      height: 0.4rem;
      margin-bottom: 0.1rem;
      justify-content: center;
      align-items: center;
      > span {
        font-size: 0.25rem;
        font-weight: 500;
        color: #9DFEFF;
        margin: 0 10px;
      }
      >img {
        height: 0.10rem;
        // width: 50px;
      }
    }
    > .horn {
      &_bottom{
        &_right {
          position: absolute;
          bottom: -1px;
          right: -1px;
          width: 21px;
        }
        &_left {
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: 21px;
        }
      }
      &_top{
        &_right {
          position: absolute;
          top: -1px;
          right: -1px;
          width: 69px;
        }
        &_left {
          position: absolute;
          top: -1px;
          left: -1px;
          width: 21px;
        }
        &_top {
          position: absolute;
          width: 100%;
          right: 0;
          left: 0;
          height: 4px;
          top: -1px;
          // width: 100%;
        }
        &_bottom {
          position: absolute;
          width: 100%;
          height: 4px;
          right: 0;
          left: 0;
          bottom: -2px;
        }
      }
    }
    .box-content {
      position: relative;
      flex: 1;
      overflow: hidden;
      > div {
        height: 100%;
      }
    }
    .box-content::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 6px;
      height: 57px;
      // background-image: url('../../../assets/screen/decorate.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .box-bg::after {
    content: '';
    position: absolute;
    // left: 0;
    right: -3px;
    top: -1px;
    // bottom: 0;
    width: 19px;
    height: 19px;
    background-image: url('../../../assets/screen/decorate3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .box-bg::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 48px;
    width: 6px;
    height: 57px;
    // background-image: url('../../../assets/screen/decorate.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
</style>