<!--
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-07-05 09:46:31
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-07-11 08:39:37
 * @FilePath: \platform-pc-front\src\components\CenterMap\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div ref="mapcenter" v-loading="isLoading" class="map"></div>
</template>
<script>
import Map from "./class/Map";
import { filterAliMapData } from "./mapUtils/index";
import china from "./mapJson/chinaMap.json";
import _ from 'lodash'
export default {
  name: "CenterMap",
  props: {
    mapOption: {
      type: Object,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(async ()=>{
    let initData = await filterAliMapData(china, "");
    let scatterData = initData.map((item)=>{
      if (item.local && item.local.length) {
        item.local.push(1)
      }
      return{
        name:item.value,
        value: item.local

      }
    })
    this.option.series[1].data = scatterData;
    // .filter(val=> val.name && val.name > 0);
    this.option.series[0].data = initData;
      setTimeout(()=>{
        this.init()
      },2000)
    })
  },
  methods: {
    goBack() {
      if(this.option.geo3D.map==='china'){ 
        return;
      };
      this.option = this.map.goBack();
    },
    init() {
      //初始化
      this.map = new Map(this.$refs.mapcenter, this.option,()=>{
        this.isLoading = false
      });
      //点击事件绑定
      this.map.OnClickEvent((params) => {
        if (!(this.map.mapStack.length < 2)) return;
        this.isLoading = true
        let supOption = JSON.parse(JSON.stringify(this.option));
        //修改相关数据
        this.option.geo3D.map = params.data?.name || "china";
        this.option.series = this.option.series.map(item=>{
          item.map = params.data?.name || "china";
          return item
        })
        //下跳事件
        this.map.goToNextLevel(
          params.data?.adcode,
          params.data?.name,
          this.option,
          supOption
        );
      });
    }
  },
  data() {
    return {
      //map实例
      map: {},
      isLoading: false,
      option: {
        geo3D: {
          type: "map",
          map: "china",
          bottom: 1,
          top: 1,
          label: {
              show: true,
              fontSize: "13",
              distance: 0,
              color: "#FFFFFF",
          },
            itemStyle: {
              borderColor: "#96AAEF",
              borderWidth: 1,
              color: '#1C3791',
            },
            viewControl:{
                 rotateSensitivity: 0,
                //  projection: 'orthographic',
                 orthographicSize: 10, //控制地图大小
                 maxOrthographicSize: 110,
                 minOrthographicSize: 110,
                //  autoRotate:false,
                //  animation:true,
                 alpha:60,
                 beta:10,
                 animationDurationUpdate:10
                 // autoRotateSpeed:5
               },
        },
        series: [
          {
            name: "map3D",
            type: "map3D",
            map: "china",
            zlevel:1,
            data: [],
          zoom: 0.5, //视角缩放比例
            itemStyle: {
              opacity:0
            },
            emphasis: {
              label: {
                show: false
              } 
            },
            viewControl:{
                 rotateSensitivity: 0,
                //  projection: 'orthographic',
                 orthographicSize: 10, //控制地图大小
                 maxOrthographicSize: 110,
                 minOrthographicSize: 110,
                //  autoRotate:false,
                //  animation:true,
                 alpha:60,
                 beta:10,
                 animationDurationUpdate:10

                 // autoRotateSpeed:5
               },
          },
          {
            type: 'scatter3D',
            name: 'scatter3D',
            coordinateSystem: 'geo3D',
            symbol: 'path://M961.391973 970.429431l-95.180864-160.210379a31.5121 31.5121 0 0 0-30.15135-16.257379H617.766851c102.414324-129.987411 239.133865-327.367744 239.133865-447.973143a345.98853 345.98853 0 0 0-691.905442 0c0 120.748636 137.006015 318.128969 239.133865 448.11638H185.907849a39.676598 39.676598 0 0 0-30.151349 16.257378L60.575635 970.572668a35.236257 35.236257 0 0 0 30.15135 53.427332h840.513638a35.164638 35.164638 0 0 0 30.15135-53.427332z m-450.479787-508.490698A113.801651 113.801651 0 1 1 624.713836 348.137082 113.944887 113.944887 0 0 1 510.912186 461.938733z',
            symbolSize: 30,
            animation: true,
            label:{
              show:true,
              position: 'top',
              distance: 0,
              textStyle: {
                color: 'rgba(247, 152, 29, 1)',
                fontSize: '16px',
                fontWeight: 'bold'
              },
              formatter:(p)=>{
                return(p.data.name)
              }
            },
            itemStyle: {
              color: 'rgba(0, 255, 186, 1)'
            },
            data: []
          }
        ],
        tooltip: {
          //数据格式化
          formatter: function (params) {
            return params.name + "<br />" + params.value;
          },
        },
        label: {
          show: true,
          color: "#FFFFFF",
          fontSize: 15,
          position: "top",
          formatter: (p) => {
            return p.name.substring(0, 2);
          },
        },
      },
    };
  },
};
</script>

<style>
.back {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

.map {
  position: relative;
}
</style>>
